import { useNavigate } from "react-router-dom";
import { styles } from './styles';
import { theme } from "../../../constants/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import layout from "../../../constants/layout";
import MonyLogo from '../../../assets/images/monyLogoWhite.png';
import Input from "../../../elements/Input";
import FooterCtaContainer from "../../../elements/FooterCtaContainer";
import { useSelector } from "react-redux";
import store from "../../../redux/store";
import { useState } from "react";
import { fetchLoggedInProfile, sendLoginOtp, verifyLoginOtp } from "../../../service/AuthService";
import { APICallStatus, AccessRoles } from "../../../constants";
import CustomButton from "../../../elements/CustomButton";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { showErrorToast } from "../../../service/toast";

function WebLogin({

}) {
  const navigate = useNavigate();
  const roles = [
    {roleKey: AccessRoles.AUDITOR, roleName: 'Auditor'},
    // {roleKey: AccessRoles.AGENT, roleName: 'Agent'},
    {roleKey: AccessRoles.ADMIN, roleName: 'Admin'},
  ]

  const countryCodes = [
    {countryCode: "IN", dialCode: '91'},
    // {roleKey: AccessRoles.AGENT, roleName: 'Agent'},
    {countryCode: "AE", dialCode: '971'},
  ]
  const [selectedRole, setSelectedRole] = useState(roles[0])
  const [selectedCountryCode, setSelectedCountryCode] = useState(countryCodes[0])
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [disableOtpRequest, setDisableOtpRequest] = useState(false);
  var timer = 59;
  const [displayTimnerValue, setDisplayTimerValue] = useState(timer);
  const [requestOtpClick, setRequestOtpClicked] = useState(false);

  const sendOtp = async () => {
    setRequestOtpClicked(true);
    setDisableOtpRequest(true);
    const response = await sendLoginOtp(selectedCountryCode, phoneNumber, selectedRole.roleKey);
    if(response?.status === APICallStatus.SUCCESS) {
      const interval = setInterval(() => {
        timer--;
        setDisplayTimerValue(timer);
        if(timer === 0) {
          setDisableOtpRequest(false);
          clearInterval(interval);
          timer = 59;
        }
      }, 1000);
    } else {
      setDisableOtpRequest(false);
      showErrorToast(response?.message);
    }
  }

  const verifyOtp = async () => {
    setIsLoading(true);
    const response = await verifyLoginOtp(selectedCountryCode, phoneNumber, otp);
    setIsLoading(false);
    if(response?.status === APICallStatus.SUCCESS) {
      await fetchLoggedInProfile();
      navigate('/dashboard')
    } else {
      showErrorToast(response?.message);
    }
  }
  
  return (
    <div className="d-flex" style={styles.container}>
      <div className="d-flex flex-column justify-content-center align-items-center" style={styles.leftContainer}>
        <img src={MonyLogo} style={styles.monyLogo}/>
        <p style={styles.title}>KYC Platform</p>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center" style={styles.rightContainer}>
        <div className="d-flex flex-column" style={{width: '100%'}}>
          <p className="mb-2" style={styles.fieldTitle}>Login as</p>
          <DropdownButton id="dropdown-basic-button" title={selectedRole.roleName} style={styles.dropdownBtn}>
            {roles.map(role => <Dropdown.Item onClick={() => setSelectedRole(role)}>{role.roleName}</Dropdown.Item>)}
          </DropdownButton>
          <p className="mb-2" style={styles.fieldTitle}>Country Code</p>
          <DropdownButton id="dropdown-basic-button" title={selectedCountryCode.countryCode} style={styles.dropdownBtn}>
            {countryCodes.map(country => <Dropdown.Item onClick={() => setSelectedCountryCode(country)}>{country.countryCode}</Dropdown.Item>)}
          </DropdownButton>
        </div>
        <Input label={`Mobile Number (+${selectedCountryCode.dialCode})`} value={phoneNumber} placeholder={'XXXXXXXXXX'} inputContainerStyles={styles.inputContainer} onChange={setPhoneNumber}/>
        <Input label={'OTP'} value={otp} placeholder={''} inputContainerStyles={styles.inputContainer} onChange={setOtp}/>
        <div className="d-flex justify-content-end" style={{width: '100%'}}>
          {disableOtpRequest ? <p style={styles.timerText}>00:{displayTimnerValue < 10 ? `0${displayTimnerValue}` : displayTimnerValue}</p> : <p style={styles.otpCta} onClick={sendOtp}>Request OTP</p>}
        </div>
        <CustomButton title={'Login'} isLoading={isLoading} style={styles.loginCta} onClick={verifyOtp} isDisabled={!(otp?.length === 6 && requestOtpClick)}/>
      </div>
    </div>
  )
}

export default WebLogin;
