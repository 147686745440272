import React, { useState, useEffect } from "react";
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "react-bootstrap";
import "./styles.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import { accessTokenSelector } from "../../../redux/selectors/auth";
import store from "../../../redux/store";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-bootstrap";
import ReactImageMagnify from "react-image-magnify";



const isLocal = false

const PreKycDashboard = () => {
  const accessToken = accessTokenSelector(store.getState());
  const [data, setData] = useState([
    // {
    //   name: "John Doe",
    //   phone: "+1 123 456 7890",
    //   category: "General",
    //   nationality: "American",
    // },
  ]);

  const [filteredData, setFilteredData] = useState(data);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [filterChanged, setFilterChanged] = useState(false);
  const [filter, setFilter] = useState("All"); // Default filter value
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");


  const [countsPending, setPendingCount] = useState(0);
  const [countsUpdateDoc, setUpdateDocCount] = useState(0);
  const [countsYtaDoc, setYtaCount] = useState(0);
  const [countsHold, setHoldCount] = useState(0);

  useEffect(() => {
    async function fetchCounts() {
      try {
        const response = await axios.post(isLocal ? 'http://localhost:8080/v1/kyc/getPreKycDashboardLeadsCounts' : "https://prod-lb-n-1003.monyyapp.com/api/v1/kyc/getPreKycDashboardLeadsCounts", {
          metaData: {},
          data: {}
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'source': 'web',
            deviceid: Math.random()
          }
        });

        const { data } = response.data;
        
        // Map response data to state
        const counts = data.reduce((acc, item) => {
          acc[item.preKycStatus] = item.count;
          return acc;
        }, {});

        setPendingCount(counts['PENDING']);
        setUpdateDocCount(counts['DOCUMENTS_UPDATE_REQUIRED']);
        setHoldCount(counts['HOLD']);
        setYtaCount(counts['YTA'])
      } catch (error) {
        console.error('Error fetching counts:', error);
      }
    }

    fetchCounts();
  }, []);



  const rowsPerPage = 20;

  const handleOpenModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedImage("");
  };

  useEffect(() => {
    console.log("i am here", currentPage)
    const fetchData = async () => {
      await fetchPaginatedData();
      // setFilterChanged(false)
    };
    fetchData()
  }, [currentPage]);

  // Fetch data on component mount
  useEffect(() => {
    console.log("sjbhjkdsjkjskd", filter, currentPage)
    if (currentPage == 1) {
      const fetchData = async () => {
        await fetchPaginatedData();
        // setFilterChanged(false)
      };
      fetchData()
    } else {
      setCurrentPage(1);
    }
  }, [filter]);

  const reset = async () => {
    if (filter == "All") {
      setCurrentPage(1)
    } else {
      setFilter('All')
    }
  }

  const fetchPaginatedData = async () => {
    setSearchQuery('')
    try {
      let data = JSON.stringify({
        "metaData": {},
        "data": {
          "pageNumber": currentPage,
          "filter": filter
        }
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: isLocal ? 'http://localhost:8080/v1/kyc/getPreKycDashboardLeadsPaginated' : 'https://prod-lb-n-1003.monyyapp.com/api/v1/kyc/getPreKycDashboardLeadsPaginated',
        headers: {
          'source': 'web',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          deviceid: Math.random()
        },
        data: data
      };

      try {
        const response = await axios.request(config);
        console.log('xvxvxvxvx', response.data);
        setData(response.data?.data);
        if (!isSearching) setFilteredData(response.data?.data);
      } catch (error) {
        console.error(error);
        throw error
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async () => {
    if (!searchQuery.trim()) {
      setFilteredData(data); // Reset to default data if search is empty
      setIsSearching(false);
      return;
    }
    setIsSearching(true);
    try {
      let data = JSON.stringify({
        "metaData": {},
        "data": {
          "pageNumber": currentPage,
          "searchQuery": searchQuery.trim(),
          "filter": filter
        }
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: isLocal ? 'http://localhost:8080/v1/kyc/getPreKycDashboardLeadsPaginated' : 'https://prod-lb-n-1003.monyyapp.com/api/v1/kyc/getPreKycDashboardLeadsPaginated',
        headers: {
          'source': 'web',
          'Content-Type': 'application/json',
          deviceid: Math.random(),
          Authorization: `Bearer ${accessToken}`,
        },
        data: data
      };

      try {
        const response = await axios.request(config);
        console.log('xvxvxvxvx', JSON.stringify(response.data));
        setData(response.data?.data);
        setIsSearching(false);
        if (!isSearching) setFilteredData(response.data?.data);
      } catch (error) {
        console.error(error);
        throw error
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = async (row) => {
    console.log(row, "roroor")
    if (!row.category) {
      toast.error("Please choose category!");
      return;
    }
    if (!row.activationCode && row.category == "Category 0") {
      toast.error("Please generate activation code for category 0!");
      return;
    }
    if (!row.action) {
      toast.error("Please choose action!");
      return;
    }
    
    if (row.action==='yta' && !row.ytaDate) {
      toast.error("Please choose date for Yet to Arrive User.!");
      return;
    }
    const data = JSON.stringify({
      metaData: {},
      data: {
        bookingId: row.bookingId,
        category: row.category,
        action: row.action,
        comments: row.comments,
        ytaDate: row.ytaDate
      },
    });

    console.log(data, "djjjs");


    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: isLocal ? "http://localhost:8080/v1/kyc/submitPreKycDashboardLeadAction" : "https://prod-lb-n-1003.monyyapp.com/api/v1/kyc/submitPreKycDashboardLeadAction",
      headers: {
        source: "web",
        "Content-Type": "application/json",
        deviceid: Math.random(),
        Authorization: `Bearer ${accessToken}`,
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      toast.success("Action submitted successfully!");
      console.log("Response:", response.data);
      fetchPaginatedData()
    } catch (error) {
      toast.error("Failed to submit the action!");
      console.error("Error:", error);
    }
  };

  const generateActivationCode = async (uuid) => {
    const data = JSON.stringify({
      metaData: {},
      data: {
        uuid: uuid,
      },
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: isLocal ? "http://localhost:8080/v1/auth/byPassKycOsvVerificationCode" : "https://prod-lb-n-1003.monyyapp.com/api/v1/auth/byPassKycOsvVerificationCode",
      headers: {
        source: "web",
        "Content-Type": "application/json",
        deviceid: Math.random(),
        Authorization: `Bearer ${accessToken}`,
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      console.log("Response:", JSON.stringify(response.data));

      // Update activation code for the specific row in filteredData
      const updatedData = filteredData.map((item) =>
        item.uuid === uuid
          ? { ...item, activationCode: response.data.data.code }
          : item
      );

      setFilteredData(updatedData); // Trigger re-render with updated data
      toast.success("Activation code generated successfully!");
      return response.data.data.code; // Return the response for further use if needed
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to generate activation code!");
      throw error;
    }
  };

  const handleWhatsAppClick = async (uuid) => {
    try {
      const data = JSON.stringify({
        metaData: {},
        data: {
          uuid: uuid,
        },
      });

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: isLocal ? "http://localhost:8080/v1/auth/getInteraktCustomerChatLink" : "https://prod-lb-n-1003.monyyapp.com/api/v1/auth/getInteraktCustomerChatLink",
        headers: {
          source: "web",
          "Content-Type": "application/json",
          deviceid: Math.random(),
          Authorization: `Bearer ${accessToken}`,
        },
        data: data,
      };

      const response = await axios.request(config);
      console.log("Response:", JSON.stringify(response.data));
      const link = response.data.data.link;

      if (link) {
        // Open the returned link in a new tab
        window.open(link, '_blank', 'noopener,noreferrer');
      } else {
        // alert('No link returned from the server.');
        toast.error("Failed to fetch WhatsApp link.");
      }
    } catch (error) {
      console.error('Error fetching the WhatsApp link:', error);
      // alert('Failed to fetch WhatsApp link.');
      toast.error("Failed to fetch WhatsApp link.");
    }
  };

  function convertToIST(timestamp) {
    timestamp = Number(timestamp);
    if (!timestamp) {
      return "NA";
    }
    const date = new Date(timestamp);
    const options = {
      timeZone: 'Asia/Kolkata',
      hour12: true,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    };
    const formatter = new Intl.DateTimeFormat('en-GB', options);
    return formatter.format(date);
  }

  const handleDateChange = (date, index) => {
    // Update date directly in the row
    const epochTimestamp = date ? date.getTime() : null;
    const updatedData = filteredData.map((item, i) =>
      i === index ? { ...item, ytaDate: epochTimestamp } : item
    );
    setFilteredData(updatedData);
  };

  const renderTableRows = () =>
    filteredData.map((row, index) => (
      <tr
  key={index}
  style={{ backgroundColor: 'white', transition: 'box-shadow 0.3s ease', boxShadow:"2px 2px 10px rgba(0,0,0,0.5)" }}
  onMouseEnter={(e) => {
    e.currentTarget.style.backgroundColor = '#dfd8fa'
    }}
  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'white'}
  className="text-xsmall border-b border-gray-300 text-gray-700"
>
        <td className="px-2 py-1 border-r border-gray-300">{convertToIST(row.createdTimestamp)}</td>
        <td className="px-2 py-1 border-r border-gray-300">{row.fullName}</td>
        <td className="px-2 py-1 border-r border-gray-300">{row.countryCode}</td>
        <td className="px-2 py-1 border-r border-gray-300">{row.phoneNumber}</td>
        <td className="px-2 py-1 border-r border-gray-300">{row.dialCode}</td>
        <td className="px-2 py-1 border-r border-gray-300" style={{ fontWeight: "bolder", fontSize: "16px" }}>{row.bookingId}</td>
        {/* <td className="px-2 py-1 border-r border-gray-300">{row.geoCode}</td> */}
        <td className="px-2 py-1 border-r border-gray-300">{row.ukdLocation ? row.ukdLocation : row.location}</td>
        <td className="px-2 py-1 border-r border-gray-300">
          {row.activationCode ? (
            row.activationCode
          ) : (
            <Button
              variant="outline-secondary"
              onClick={() => generateActivationCode(row.uuid)}
              className="bg-purple-300 text-purple-800 px-2 py-1 rounded" style={{ fontSize: "12px" }}
            >
              Generate
            </Button>
          )}
        </td>
        <td className="px-2 py-1 border-r border-gray-300">
          <select
            className="border rounded p-2"
            value={row.category || "Choose"}
            onChange={(e) => {
              row.category = e.target.value;
              setFilteredData([...filteredData]);
            }}
          >
            {["Choose", "Category 0", "Category 1", "Category 2", "Category 3"].map(
              (option, i) => (
                <option key={i} value={option}>
                  {option}
                </option>
              )
            )}
          </select>
        </td>
        <td className="px-2 py-1 border-r border-gray-300">{row.nationalityType}</td>
        <td>
          <div style={{}} className={`px-2 py-1 border-r border-gray-300 
  ${row.preKycStatus === 'AGENT_ASSIGNED' ? 'bg-success text-white' : ''} 
  ${row.preKycStatus === 'DOCUMENTS_UPDATE_REQUIRED' ? 'bg-primary text-white' : ''} 
  ${row.preKycStatus === 'REJECT' ? 'bg-danger text-white' : ''} 
  ${row.preKycStatus === 'HOLD' ? 'bg-warning text-dark' : ''} 
  ${row.preKycStatus === 'YTA' ? 'bg-info text-dark' : ''} 
  ${(row.preKycStatus === 'PENDING' || !row.preKycStatus) ? 'bg-secondary text-white' : ''}
`}>{row.preKycStatus ? (row.preKycStatus==='DOCUMENTS_UPDATE_REQUIRED' ? 'UPDATE' : row.preKycStatus ) : 'PENDING'}</div></td>
        <td className="px-2 py-1 border-r border-gray-300" style={{ fontSize: "12px" }}>
          <select
            className={`border rounded p-2 ${row.action === "assign agent"
              ? "bg-success text-white"
              : row.action === "reject"
                ? "bg-danger text-white"
                : row.action === "update documents"
                  ? "bg-primary text-white"
                  : row.action === "hold"
                    ? "bg-warning text-dark"
                    : row.action === "yta"
                    ? "bg-info text-dark"
                    : ""
                    
              }`}
            value={row.action || ""}
            onChange={(e) => {
              const updatedData = filteredData.map((item, i) =>
                i === index ? { ...item, action: e.target.value } : item
              );
              setFilteredData(updatedData);
            }}
          >
            <option value="">Select Action</option>
            <option value="assign agent">Assign Agent</option>
            <option value="reject">Reject</option>
            <option value="update documents">Update Documents</option>
            <option value="hold">On Hold</option>
            <option value="yta">YTA</option>
          </select>
          {row.action === "yta" && (
        <div className="mt-2">
          <DatePicker
            selected={row.ytaDate ? new Date(Number(row.ytaDate)) : null}
            onChange={(e)=>handleDateChange(e, index)}
            placeholderText="Select Date"
            className="form-control"
            dateFormat="dd MMM yyyy" // Set date format
          />
        </div>
      )}
        </td>
        <td className="px-2 py-1 border-r border-gray-300">
          {row.preKycStatus==='YTA' && <div className="px-2 py-1 border-r border-gray-300" style={{fontWeight:"bold", color:"red"}}>{convertToIST(row.ytaDate)?.split(",")[0]}</div>}
          {row.isEditing ? (
            <textarea
              className="rounded text-xs p-2 w-full"
              placeholder="Add a comment"
              value={row.comments || ""}
              onChange={(e) => {
                const updatedData = filteredData.map((item, i) =>
                  i === index ? { ...item, comments: e.target.value } : item
                );
                setFilteredData(updatedData);
              }}
              onBlur={() => {
                const updatedData = filteredData.map((item, i) =>
                  i === index ? { ...item, isEditing: false } : item
                );
                setFilteredData(updatedData);
              }}
            />
          ) : row.comments ? (
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ maxWidth: "200px" }}
            >
              <span
                className="comments-text"
                style={{
                  // display: "-webkit-box",
                  // WebkitLineClamp: 2,
                  // WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  // textOverflow: "ellipsis",
                  lineHeight: "1.5em", // For consistent spacing
                  maxHeight: "3em", // 2 lines of text
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                }}
              >
                {row.comments}
              </span>
              <button
                onClick={() => {
                  const updatedData = filteredData.map((item, i) =>
                    i === index ? { ...item, isEditing: true } : item
                  );
                  setFilteredData(updatedData);
                }}
                className="btn btn-link text-decoration-none text-primary p-0 ms-2"
              >
                ✏️
              </button>
            </div>

          ) : (
            <button
              onClick={() => {
                const updatedData = filteredData.map((item, i) =>
                  i === index ? { ...item, isEditing: true } : item
                );
                setFilteredData(updatedData);
              }}
              className="text-gray-500" style={{ border: "none", fontSize: "12px" }}
            >
              Remarks
            </button>
          )}
        </td>
        <td className="px-2 py-1">
          <Button
            onClick={() => handleSubmit(row)}
            disabled={!row.category || !row.action || !row.comments} // Disable if category, action, or comment is empty
            style={{
              backgroundColor: !row.category || !row.action || !row.comments ? "black" : "#613AE7",
              fontSize: "12px",
              border: "none",
              color: !row.category || !row.action || !row.comments ? "white" : "white", // Optional for text color
            }}
            className="px-2 py-1 rounded"
          >
            Submit
          </Button>
        </td>
        <td><button
          onClick={() => handleWhatsAppClick(row.uuid)}
          title="Contact on WhatsApp"
          style={{
            color: '#25d366',
            fontSize: '1.2em',
            textAlign: 'center',
            width: '100%',
            background: 'none',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          <FontAwesomeIcon icon={faMessage} />
        </button></td>
        <td>
          <button
            onClick={() => {
              const updatedData = filteredData.map((item, i) =>
                i === index ? { ...item, isEditing: true } : item
              );
              setFilteredData(updatedData);
            }}
            className="text-gray-500" style={{ border: "none", fontSize: "12px" }}
          >
            Edit
          </button>
        </td>
        <td>
                <div className="fas fa-info-circle text-primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOpenModal(row.passportFrontImage)}>View</div>
              </td>
              <td>
                <div className="fas fa-info-circle text-primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOpenModal(row.passportBackImage)}>View</div>
              </td>
              <td>
                <div className="fas fa-info-circle text-primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOpenModal(row.visaImage)}>View</div>
              </td>
      </tr>
    ));

  return (
    <div className="p-4">
      <div style={{ display: "flex", width: "100%", height: "30px", backgroundColor: "#e0e0e0", borderRadius: "5px", overflow: "hidden", marginBottom:"16px" }}>
        <div
          style={{
            flex: countsPending / (countsPending + countsHold + countsUpdateDoc + countsYtaDoc),
            backgroundColor: "#f39c12",
            textAlign: "center",
            color: "white",
            fontSize: "16px",
            fontWeight: "bolder",
            lineHeight: "30px" // Ensures vertical alignment
          }}
          title={`Pending: ${countsPending}`}
        >
          {countsPending}
        </div>
        <div
          style={{
            flex: countsUpdateDoc / (countsPending + countsHold + countsUpdateDoc + countsYtaDoc),
            backgroundColor: "#3498db",
            textAlign: "center",
            color: "white",
            fontSize: "16px",
            fontWeight: "bolder",
            lineHeight: "30px" // Ensures vertical alignment
          }}
          title={`Update Documents: ${countsUpdateDoc}`}
        >
         {countsUpdateDoc}
        </div>
        <div
          style={{
            flex: countsHold / (countsPending + countsHold + countsUpdateDoc + countsYtaDoc),
            backgroundColor: "#f1c40f",
            textAlign: "center",
            color: "white",
            fontSize: "16px",
            fontWeight: "bolder",
            lineHeight: "30px" // Ensures vertical alignment
          }}
          title={`Hold: ${countsHold}`}
        >
          {countsHold}
        </div>
        <div
          style={{
            flex: countsYtaDoc / (countsPending + countsHold + countsUpdateDoc + countsYtaDoc),
            backgroundColor: "purple",
            textAlign: "center",
            color: "white",
            fontSize: "16px",
            fontWeight: "bolder",
            lineHeight: "30px" // Ensures vertical alignment
          }}
          title={`YTA: ${countsYtaDoc}`}
        >
          {countsYtaDoc}
        </div>
        <div
          style={{
            backgroundColor: "#000",
            textAlign: "center",
            color: "white",
            fontSize: "12px",
            fontWeight: "bolder",
            padding:"1px",
            lineHeight: "30px" // Ensures vertical alignment
          }}
          title={`Total: ${countsPending + countsHold + countsUpdateDoc + countsYtaDoc}`}
        >
          Total - {countsPending + countsHold + countsUpdateDoc + countsYtaDoc}
        </div>

      </div>
      <div className="mb-4 flex items-center">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search Booking Id"
          className="border rounded px-4 py-2 w-full text-sm"
        />
        <button
          onClick={handleSearch}
          className="bg-purple-300 text-purple-800 px-4 py-2 ml-2 rounded text-sm border-0"
          style={{ marginLeft: "10px" }}
        >
          Search
        </button>
        <button
          onClick={fetchPaginatedData}
          className="px-4 py-2 ml-2 rounded text-sm border-0 bg-transparent"
        >
          Clear
        </button>
        <button
          onClick={reset}
          className="px-4 py-2 ml-2 rounded text-sm border-0 bg-transparent"
        >
          Reset
        </button>
        <select
          value={filter}
          onChange={(e) => { setFilter(e.target.value) }}
          className="border rounded px-4 py-2 ml-2 text-sm"
          style={{ marginLeft: "10px", float: "right", border: "none" }}
        >
          <option value="All">All</option>
          <option value="Pending">Pending</option>
          <option value="UpdateDocuments">Update Documents</option>
          <option value="Hold">Hold</option>
          <option value="Yta">YTA</option>
        </select>
      </div>


      <div className="overflow-y-auto h-96 border border-gray-300 rounded" style={{height:"500px"}}>
        <table className="table-fixed w-full border-collapse" style={{ fontSize: "12px", minWidth: "100%" }}>
        <thead>
            <tr style={{ backgroundColor: "grey", color: "white", fontSize: "12px", textAlign: "center", width: "100%" }}>
              <th className="px-2 py-1 border-r border-gray-300">Submission Time</th>
              <th className="px-2 py-1 border-r border-gray-300">Name</th>
              <th className="px-2 py-1 border-r border-gray-300">Country Code</th>
              <th className="px-2 py-1 border-r border-gray-300">Phone Number</th>
              <th className="px-2 py-1 border-r border-gray-300">Dial Code</th>
              <th className="px-2 py-1 border-r border-gray-300">Booking Id</th>
              {/* <th className="px-2 py-1 border-r border-gray-300">Geo Code</th> */}
              <th className="px-2 py-1 border-r border-gray-300">Submission Location</th>
              <th className="px-2 py-1 border-r border-gray-300">Activation Code</th>
              <th className="px-2 py-1 border-r border-gray-300">Category</th>
              <th className="px-2 py-1 border-r border-gray-300">Nationality</th>
              <th className="px-2 py-1 border-r border-gray-300">Status</th>
              <th className="px-2 py-1 border-r border-gray-300">Action</th>
              <th className="px-2 py-1 border-r border-gray-300">Comments</th>
              <th className="px-2 py-1">Submit</th>
              <th className="px-2 py-1">Whatsapp</th>
              <th className="px-2 py-1">Passport Number</th>
              <th className="px-2 py-1">Passport Front</th>
              <th className="px-2 py-1">Passport Back</th>
              <th className="px-2 py-1">Visa Image</th>
            </tr>
          </thead>
          <tbody>{renderTableRows()}</tbody>
        </table>
        {
          filteredData.length === 0 &&
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
              fontSize: "1.2em",
              color: "#6c757d", // Optional for styling, use any color you like
            }}
          >
            No data available
          </div>
        }
      </div>

      {!isSearching && (
        <div className="mt-4 flex float-end">
          <Button
            variant="outline-secondary"
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            className=""
            style={{border: "none"}}
          >
            Previous
          </Button>
          <span className="px-4 py-2 text-sm">{currentPage}</span>
          <Button
            variant="outline-secondary"
            onClick={() => setCurrentPage((prev) => prev + 1)}
            className=""
            style={{border: "none"}}
          >
            Next
          </Button>
        </div>
      )}

      <ToastContainer />
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {selectedImage && (
            <ReactImageMagnify
              {...{
                smallImage: {
                  alt: "Image",
                  isFluidWidth: true,
                  src: selectedImage,
                },
                largeImage: {
                  src: selectedImage,
                  width: 1200,
                  height: 1200,
                },
              }}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};


export default PreKycDashboard;