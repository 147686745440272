export const Config = {
  apis: {
    auth: {
      login: `/v1/auth/login`,
      verifyOtp: `/v1/auth/authenticate`,
      validateToken: `/v1/auth/validateToken`,
      fetchLoggedInUser: `/v1/auth/getUserInfo`,
    },
    kyc: {
      kycUserList: `/v1/kyc/getKycBookingsByAgent`,
      auditorKycUserList: `/v1/kyc/getKycBookingsByAuditor`,
      fetchUserKyc: `/v1/kyc/getKycData`,
      adminUnmappedKycUserList: `/v1/kyc/getAllUnMappedKycSlotBookings`,
      verifyKycOtp: `/v1/kyc/validateAndStartKycProcess`,
      agentKycSubmit: `/v1/kyc/updateKycDetailsByAgent`,
      auditorKycSubmit: `/v1/kyc/updateKycDetailsByAuditor`,
      fetchAllAgents: `/v1/kyc/getAllAgents`,
      mapBookingToAgent: `/v1/kyc/mapBookingToAgent`,
      uploadUserDoc: `/v1/onboarding/file/upload`,
    },
    accounts: {
      closureTickets: `/v1/tickets/getAllAccountClosureTickets`,
      walletBalance: `/v1/wallet/fetchBalanceForAccountClosure`,
      closeRequest: `/v1/wallet/closeWallet`,
    }
  }
}