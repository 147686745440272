import { APICallStatus } from "../../constants";
import { Config } from "../../constants/config";
import { setLoggedInUser } from "../../redux/actions/app";
import store from "../../redux/store";
import { saveTokens } from "../../redux/thunks/auth.thunk";
import { ApiService } from "../ApiService";
import { StorageService } from "../StorageService";


export const sendLoginOtp = async (countryCode,phoneNumber, role) => {
  try {
    console.log(process.env);
    const response = await ApiService.postApi(Config.apis.auth.login, {
      phoneNumber: `${countryCode.dialCode}${phoneNumber}`,
      countryCode: countryCode.countryCode,
      dialCode: `+${countryCode.dialCode}`,
      role,
    });
    console.log(response);
    return {
      status: APICallStatus.SUCCESS,
      verificationId: response?.data?.verificationId,
    };
  } catch (error) {
    console.log(error?.response?.data?.error?.shortMessage);
    return {
      status: APICallStatus.ERROR,
      message: error?.response?.data?.error?.shortMessage || 'Something went wrong',
    };
  }
}

export const verifyLoginOtp = async (selectedCountryCode, phoneNumber, otp) => {
  try {
    console.log(process.env);
    const response = await ApiService.postApi(Config.apis.auth.verifyOtp, {
      phoneNumber: `${selectedCountryCode.dialCode}${phoneNumber}`,
      otp
    });
    console.log(response);
    saveTokens({
      accessToken: response?.data?.data?.accessToken,
      refreshToken: response?.data?.data?.refreshToken,
    });
    return {
      status: APICallStatus.SUCCESS,
    };
  } catch (error) {
    console.log(error);
    return {
      status: APICallStatus.ERROR,
      message: error?.response?.data?.error?.shortMessage || 'Something went wrong',
    };
  }
}

export const isTokenValid = async () => {
  try {
    const accessToken = await StorageService.getAccessToken();
    const refreshToken = await StorageService.getRefreshToken();
    saveTokens({
      accessToken,
      refreshToken,
    });
    const response = await ApiService.postApi(Config.apis.auth.validateToken, {});
    return {
      status: response?.data?.status === "SUCCESS" ? APICallStatus.SUCCESS : APICallStatus.ERROR,
    };
  } catch (error) {
    return {
      status: APICallStatus.ERROR,
      message: error?.response?.data?.error?.shortMessage || 'Something went wrong',
    };
  }
}

export const fetchLoggedInProfile = async () => {
  try {
    const dispatch = store.dispatch;
    const response = await ApiService.postApi(Config.apis.auth.fetchLoggedInUser, {});
    dispatch(setLoggedInUser(response?.data?.data));
  } catch (error) {
    console.log(error);
  }
}