import logo from './logo.svg';
import './App.css';
import { Routes, Route } from "react-router-dom";
import Header from './components/Header';
import AgentDashboard from './components/AgentsComponents/AgentDashboard';
import PendingKycApprovals from './components/AgentsComponents/PendingKycApprovals';
import UserDetails from './components/AgentsComponents/userDetails';
import MobileLogin from './components/AgentsComponents/MobileLogin';
import AuthVerification from './components/AuthVerification';
import ProtectedRoute from './components/ProtectedRoute';
import WebLogin from './components/AuditorComponents/WebLogin';
import AuditorDashboard from './components/AuditorComponents/AuditorDashboard';
import { useSelector } from 'react-redux';
import { loggedInUserSelector } from './redux/selectors/app';
import { AccessRoles } from './constants';
import layout from './constants/layout';
import Wrapper from './components/Wrapper';
import AdminDashboard from './components/AdminComponents/AdminDashboard';
import LoaderPage from './components/LoaderComponents/LoaderPage';

function App() {
  const loggedInUser = useSelector(loggedInUserSelector);
  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <AuthVerification />
          </>
        }
      />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            {loggedInUser?.userRole === AccessRoles.AGENT ? <Wrapper>
              <Header
                title={'Agent Dashboard'}
              />
              <AgentDashboard />
            </Wrapper> : loggedInUser?.userRole === AccessRoles.AUDITOR ? <Wrapper isWebWrapper>
              <Header
                title={'Auditor Dashboard'}
              />
              <AuditorDashboard />
            </Wrapper> : <Wrapper isWebWrapper>
              <Header
                name={`${loggedInUser?.fullName} (${loggedInUser?.email ? loggedInUser?.email : "N/A"})`}
                title={'Mony Admin'}
              />
              <AdminDashboard />
            </Wrapper>}
          </ProtectedRoute>
        }
      />
      <Route
        path="/pendingUserList"
        element={
          <ProtectedRoute>
            <Wrapper>
              <Header
                title={'Pending KYC'}
                showBackButton
              />
              <PendingKycApprovals />
            </Wrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/userDetails"
        element={
          <ProtectedRoute>
            <Wrapper>
              <Header
                title={'User details'}
                showBackButton
              />
              <UserDetails />
            </Wrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/login"
        element={
          layout.isMobileDevice ? <Wrapper>
            <MobileLogin />
          </Wrapper> : <Wrapper isWebWrapper>
            <WebLogin/>
          </Wrapper>
        }
      />
      <Route
        path="/loader"
        element={
          <LoaderPage/>
        }
      />
      <Route
        path="/loaderFailure"
        element={
          <LoaderPage/>
        }
      />
      <Route
        path="/loaderSuccess"
        element={
          <LoaderPage/>
        }
      />
    </Routes>
  );
}

export default App;
