import { useNavigate } from "react-router-dom";
import { styles } from './styles';
import { theme } from "../../../constants/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faCheckSquare, faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { APICallStatus } from "../../../constants";
import { fetchAuditorKycUserList } from "../../../redux/thunks/kyc.thunk";
import AuditorUserDetailsModal from "../../modals/AuditorUserDetailsModal";
import layout from "../../../constants/layout";
import Lottie from "react-lottie";
import LogoAnimation from '../../../assets/lotties/logoLoaderAnimation.json';

function LoaderPage({

}) {
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: LogoAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <div style={styles.container}>
      <Lottie options={defaultOptions}
        height={layout.window.toNormW(200)}
        width={layout.window.toNormW(200)}
      />
    </div>
  );
}

export default LoaderPage;
