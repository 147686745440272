import React, { useEffect, useState, useRef } from 'react'
import { styles } from './styles'
import { fetchUserKycData, uploadUserDoc } from '../../../redux/thunks/kyc.thunk';
import { APICallStatus } from '../../../constants';
import { showErrorToast } from '../../../service/toast';
import { Form, Button, Modal } from 'react-bootstrap';
import CustomButton from '../../../elements/CustomButton';
import Input from '../../../elements/Input';

export default function UpdateUserDocs() {
  const [userData, setUserData] = useState();
  const [searchQueryText, setSearchQueryText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [passportFront, setPassportFront] = useState(null);
  const [passportBack, setPassportBack] = useState(null);
  const [visa, setVisa] = useState(null);
  const [uploadLoader, setUploadLoader] = useState(null);
  const [uploadLoaderButton, setUploadLoaderButton] = useState(false);

  const [passportFrontPreview, setPassportFrontPreview] = useState(null);
  const [passportBackPreview, setPassportBackPreview] = useState(null);
  const [visaPreview, setVisaPreview] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState(null);

  const passportFrontInputRef = useRef(null);
  const passportBackInputRef = useRef(null);
  const visaInputRef = useRef(null);

  const FileType = {
    PassportFront: 'passport-front.jpg',
    PassportBack: 'passport-back.jpg',
    Visa: 'visa',
  }

  const searchUserData = async () => {
    setIsLoading(true);
    const response = await fetchUserKycData({ query: searchQueryText });
    setIsLoading(false);
    if (response?.status === APICallStatus.SUCCESS) {
      setUserData(response?.userData);
      // Reset file inputs and previews when a new search is made
      resetFileInputs();
    } else {
      showErrorToast(response?.message);
    }
  }

  const handleUpload = async (fileType, file) => {
    try {
      setUploadLoaderButton(true)
      setUploadLoader(fileType);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', fileType.split('.')?.[0]);
      formData.append('agentPortal', true);
      formData.append('userUuid', userData?.uuid);

      const response = await uploadUserDoc(formData);
      if (response?.status === APICallStatus.SUCCESS) {
        await searchUserData();
        setUploadLoaderButton(false)
      } else {
        showErrorToast(response?.message);
      }
      setUploadLoader(null);
      setShowPreviewModal(false); // Close modal after upload
      setUploadLoaderButton(false)
    } catch (error) {
      console.log(error);
    }
  }

  const handleFileChange = (e, fileType) => {
    const file = e?.target?.files?.[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);

      switch (fileType) {
        case FileType.PassportFront:
          setPassportFront(file);
          setPassportFrontPreview(previewUrl);
          break;
        case FileType.PassportBack:
          setPassportBack(file);
          setPassportBackPreview(previewUrl);
          break;
        case FileType.Visa:
          setVisa(file);
          setVisaPreview(previewUrl);
          break;
      }

      setSelectedFileType(fileType);
      setShowPreviewModal(true);
    }
  }

  const resetFileInputs = () => {
    // Reset all file inputs and preview images
    setPassportFront(null);
    setPassportBack(null);
    setVisa(null);
    setPassportFrontPreview(null);
    setPassportBackPreview(null);
    setVisaPreview(null);

    // Clear file input fields
    if (passportFrontInputRef.current) passportFrontInputRef.current.value = '';
    if (passportBackInputRef.current) passportBackInputRef.current.value = '';
    if (visaInputRef.current) visaInputRef.current.value = '';
  }

  return (
    <div className='d-flex flex-column' style={styles.container}>
      <div className="d-flex flex-row mb-4" style={styles.inputContainer}>
        <Input
          label={`Enter user's uuid or phone number`}
          value={searchQueryText}
          onChange={setSearchQueryText}
        />
      </div>
      <CustomButton
        title={'Search'}
        onClick={searchUserData}
        isLoading={isLoading}
      />

      {userData ? (
        <div className='d-flex flex-column mt-2'>
          <p style={styles.userTitle}>User details: {userData?.userName} ({userData?.userPhoneNumber})</p>

          <div className='d-flex flex-row justify-content-between pb-5'>
            {/* Passport Front */}
            <div className='px-2 d-flex flex-column align-items-center justify-content-center' style={styles.docContainer}>
              <p style={styles.docTitle}>Passport front image</p>
              {userData?.passportFrontImage.includes("https:") ? (
                <a href={userData?.passportFrontImage} target='_blank'>
                  <img src={userData?.passportFrontImage} style={styles.docImage} />
                </a>
              ) : (
                <a>
                  <img
                    src={"https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg"}
                    style={styles.docImage}
                  />
                </a>
              )}
              <Form.Group controlId="formFile" className="mt-3">
                <Form.Label>Choose new file to replace</Form.Label>
                <Form.Control
                  ref={passportFrontInputRef}
                  type="file"
                  onChange={(e) => handleFileChange(e, FileType.PassportFront)}
                />
              </Form.Group>
              {/* <CustomButton
                title={'Upload'}
                buttonStyles={styles.uploadCta}
                onClick={() => handleUpload(FileType.PassportFront, passportFront)}
                isDisabled={!passportFront}
                isLoading={uploadLoader === FileType.PassportFront}
              /> */}
            </div>

            {/* Passport Back */}
            <div className='px-2 d-flex flex-column' style={styles.docContainer}>
              <p style={styles.docTitle}>Passport back image</p>
              {userData?.passportBackImage.includes("https:") ? (
                <a href={userData?.passportBackImage} target='_blank'>
                  <img src={userData?.passportBackImage} style={styles.docImage} />
                </a>
              ) : (
                <a>
                  <img
                    src={"https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg"}
                    style={styles.docImage}
                  />
                </a>
              )}
              <Form.Group controlId="formFile" className="mt-3">
                <Form.Label>Choose new file to replace</Form.Label>
                <Form.Control
                  ref={passportBackInputRef}
                  type="file"
                  onChange={(e) => handleFileChange(e, FileType.PassportBack)}
                />
              </Form.Group>
              {/* <CustomButton
                title={'Upload'}
                buttonStyles={styles.uploadCta}
                onClick={() => handleUpload(FileType.PassportBack, passportBack)}
                isDisabled={!passportBack}
                isLoading={uploadLoader === FileType.PassportBack}
              /> */}
            </div>

            {/* Visa */}
            <div className='px-2 d-flex flex-column' style={styles.docContainer}>
              <p style={styles.docTitle}>Secondary image</p>
              {userData?.visaImage.includes("https:") ? (
                <a href={userData?.visaImage} target='_blank'>
                  <img src={userData?.visaImage} style={styles.docImage} />
                </a>
              ) : (
                <a>
                  <img
                    src={"https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg"}
                    style={styles.docImage}
                  />
                </a>
              )}
              <Form.Group controlId="formFile" className="mt-3">
                <Form.Label>Choose new file to replace</Form.Label>
                <Form.Control
                  ref={visaInputRef}
                  type="file"
                  onChange={(e) => handleFileChange(e, FileType.Visa)}
                />
              </Form.Group>
              {/* <CustomButton
                title={'Upload'}
                buttonStyles={styles.uploadCta}
                onClick={() => handleUpload(FileType.Visa, visa)}
                isDisabled={!visa}
                isLoading={uploadLoader === FileType.Visa}
              /> */}
            </div>
          </div>
        </div>
      ) : null}

      {/* Preview Modal */}
      <Modal show={showPreviewModal} onHide={() => {
        resetFileInputs();
        setShowPreviewModal(false);
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedFileType === FileType.PassportFront && passportFrontPreview && (
            <img src={passportFrontPreview} alt="Passport Front" style={{ width: '100%' }} />
          )}
          {selectedFileType === FileType.PassportBack && passportBackPreview && (
            <img src={passportBackPreview} alt="Passport Back" style={{ width: '100%' }} />
          )}
          {selectedFileType === FileType.Visa && visaPreview && (
            <img src={visaPreview} alt="Visa" style={{ width: '100%' }} />
          )}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={() => {
            resetFileInputs();
            setShowPreviewModal(false);}}>
            Close
          </Button> */}
          <CustomButton
            title={"Upload File"}
            variant="primary"
            onClick={() =>
              handleUpload(
                selectedFileType,
                selectedFileType === FileType.PassportFront ? passportFront : selectedFileType === FileType.PassportBack ? passportBack : visa
              )
            }
            isLoading={uploadLoaderButton}
          >
            Upload
          </CustomButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
